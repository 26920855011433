<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title>Documentos por fecha</v-card-title>
          <GChart type="LineChart" :data="chartDates" :options="chartOptions" />
          <v-data-table
            dense
            :headers="header_date"
            :items="perDate"
            mobile-breakpoint="0"
            :items-per-page="5"
            class="table-cursor tbl pt-12"
            loading-text="Cargando ..."
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title>Tipo de documento</v-card-title>
          <GChart type="PieChart" :data="chartType" :options="chartOptions" />
          <v-data-table
            dense
            :headers="header_type"
            :items="perType"
            mobile-breakpoint="0"
            :items-per-page="5"
            class="table-cursor tbl pt-12"
            loading-text="Cargando ..."
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-title>Por Status</v-card-title>
          <GChart type="PieChart" :data="chartStatus" :options="chartOptions" />
          <v-data-table
            dense
            :headers="header_type"
            :items="perStatus"
            mobile-breakpoint="0"
            :items-per-page="5"
            class="table-cursor tbl pt-12"
            loading-text="Cargando ..."
          />
        </v-card>
      </v-col>

      
    </v-row>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import { GChart } from "vue-google-charts";
export default {
  components: { GChart },
  mounted() {
    this.get_docs();
  },
  data() {
    return {
      store_per_city: null,
      header_city: [
        {
          text: "Ciudad",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "value",
        },
      ],
      header_type: [
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "value",
        },
      ],
      header_date: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "value",
        },
      ],
      table: "stores",
      dialog: false,
      item_dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      employees: [],
      items_bygroup: [],
      perType: [],
      perStatus: [],
      perQR: [],
      perCity: [],
      perDate: [],
      perPro: [],
      groups: [],
      loaded: false,
      chartData: [],
      chartType: [],
      chartDates: [],
      chartStatus: [],
      chartGeo: [],
      chartProviders: [],
      chartQR: [],
      chartOptions: {
        curveType: "function",
        legend: { position: "bottom" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "QR4",
        isStacked: false,
        hAxis: {
          title: "Fechas",
          slantedText: true,
          slantedTextAngle: 90,
        },
      },
    };
  },
  methods: {
    get_docs() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "docs",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.company.account },
        ],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table", qry, (data) => {
        this.$store.dispatch("setloading", false);
        this.charts_docs(data);
      });
    },
        charts_docs(data) {
      var allDta = data;
      var result = [];

      data.reduce(function (res, value) {
        if (!res[value.city]) {
          res[value.city] = { label: value.city, value: 0 };
          result.push(res[value.city]);
        }
        res[value.city].value += 1;
        return res;
      }, {});

      var dtSet = [];
      dtSet.push(["Ciudad", "Clientes"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });
      this.perCity = result;
      this.chartData = dtSet;

      // BY CATEGORY
      data = allDta;
      result = [];
      data.reduce(function (res, value) {
        if (!res[value.doc_type]) {
          res[value.doc_type] = { label: value.doc_type, value: 0 };
          result.push(res[value.doc_type]);
        }
        res[value.doc_type].value += 1;
        return res;
      }, {});

      this.perType = result;

      dtSet = [];
      dtSet.push(["Tipo", "Cantidad"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.chartType = dtSet;

      // BY STATUS
      data = allDta;
      result = [];
      data.reduce(function (res, value) {
        if (!res[value.status]) {
          res[value.status] = { label: value.status, value: 0 };
          result.push(res[value.status]);
        }
        res[value.status].value += 1;
        return res;
      }, {});

      this.perStatus = result;

      dtSet = [];
      dtSet.push(["Tipo", "Cantidad"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.chartStatus = dtSet;



      dtSet = [];
      dtSet.push(["Tipo", "Cantidad"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.chartGeo = dtSet;

      // With QR
      data = allDta;
      result = [];
      var qr = 0;
      var no_qr = 0;
      data.forEach((rg) => {
        if (rg.qr) {
          qr += 1;
        } else {
          no_qr += 1;
        }
      });
      result = [
        { label: "Con QR", value: qr },
        { label: "Sin QR", value: no_qr },
      ];

      this.perQR = result;

      dtSet = [];
      dtSet.push(["Tipo", "Cantidad"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.chartQR = dtSet;

      // BY DATE
      data = allDta;
      result = [];
      data.reduce(function (res, value) {
        var dta = value.issue_date.split(" ");
        if (!res[dta[0]]) {
          res[dta[0]] = { label: dta[0], value: 0 };
          result.push(res[dta[0]]);
        }
        res[dta[0]].value += 1;
        return res;
      }, {});
      result.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
      this.perDate = result;
      dtSet = [];
      dtSet.push(["Fecha", "Cantidad"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });
      this.chartDates = dtSet;

      // With PER Provider
      data = allDta;
      result = [];
      var respon = [];
      respon.push({ label: "menudigital", value: 0 });
      respon.push({ label: "instagram", value: 0 });
      respon.push({ label: "queresto", value: 0 });
      respon.push({ label: "qrco.de", value: 0 });
      respon.push({ label: "drive.google", value: 0 });
      respon.push({ label: "cluvi", value: 0 });
      respon.push({ label: "Otros", value: 0 });

      data.forEach((rg) => {
        console.log(rg);
        if (rg.qr !== "") {
          if (rg.qr.match(/menudigital/)) {
            respon.find((el) => el.label === "menudigital").value += 1;
          } else {
            if (rg.qr.match(/instagram/)) {
              respon.find((el) => el.label === "instagram").value += 1;
            } else {
              if (rg.qr.match(/queresto/)) {
                respon.find((el) => el.label === "queresto").value += 1;
              } else {
                if (rg.qr.match(/qrco.de/)) {
                  respon.find((el) => el.label === "qrco.de").value += 1;
                } else {
                  if (rg.qr.match(/drive.google/)) {
                    respon.find((el) => el.label === "drive.google").value += 1;
                  } else {
                    if (rg.qr.match(/cluvi/)) {
                      respon.find((el) => el.label === "cluvi").value += 1;
                    } else {
                      respon.find((el) => el.label === "Otros").value += 1;
                    }
                  }
                }
              }
            }
          }
        }
      });

      this.perPro = respon;
      dtSet = [];
      dtSet.push(["Provider", "Cantidad"]);
      respon.forEach((rg) => {
        console.log(rg);
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });
      this.chartProviders = dtSet;
    },
    get_items() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "doc_items",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.company.account },
        ],
      };
      this.$store.dispatch("setloading", true);
      webserver("get_table", qry, (data) => {
        this.$store.dispatch("setloading", false);
        this.charts_items(data);
      });
    },

    charts_items(data) {
      console.log(data);
      // var allDta = data;
      // var result = [];
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
